import { Container, useColorMode } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerBlepharo from "../components/choc/BannerBlepharo"
import BannerHyaloron from "../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaserVeo from "../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../components/choc/QuadTeaser"
import TitleScrollHeader from "../components/choc/TitleScrollHeader"
import Layout from "../components/layout/Layout"
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink"
import ThreeColumnGridBoxesDocs from "../components/ThreeColumnGridBoxesDocs"
import TwoColumnGridBoxesAllgemein from "../components/ThreeColumnGridBoxesAllgemein"
import ImgTextModule from "../components/ImgTextModule"
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"
import { ModalAd } from "../components/ModalAd"
import { Box, Flex, Heading, Button, Spacer, Text } from "@chakra-ui/react"
const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [isToggledAdModal, setToggleAdModal] = useState(null)
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <Layout
      title={"Willkommen bei VeoMed | JadeKlinik"}
      description={
        "Mund- Kiefer- und Gesichtschirurgie. Ästhetische Behandlungen und Schönheitsoperationen. Schönheits-OP's, Behandlungen und Korrekturen: JadeKlinik in Wilhelmshaven."
      }
    >
      <TitleScrollHeader />
      <Container variant="layoutContainer" mt={0}>
        <ImgTextModule
          src={"dentistBanner.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h={{ base: "80vh", md: "50vh", lg: "50vh", xl: "50vh" }}
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              {" "}
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  fontWeight="normal"
                  fontSize={{ base: "md", lg: "md" }}
                >
                  Wir suchen einen
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  fontWeight="bold"
                  fontSize={{ base: "2xl", lg: "4xl" }}
                >
                  Mund-Kiefer-Gesichtschirurgen
                </Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  fontWeight="bold"
                  maxW={{ base: "100%", lg: "20%" }}
                  fontSize={{ base: "md", lg: "md" }}
                >
                  Als Angestellter oder Partner in einer der führenden
                  Kieferchirurgie Praxen in Nordwestdeutschland.
                </Text>
              </FadeInAnimation>
              <Flex flexDirection={{ base: "column", md: "row" }}>
                <Button
                  mt={4}
                  backgroundColor="brand.violet.primary"
                  variant="solid"
                  color="white"
                  onClick={() => setToggleAdModal(true)}
                  _hover={{
                    backgroundColor: "brand.violet.medium",
                    color: "white",
                  }}
                >
                  Mehr Informationen & Video
                </Button>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink isExternal={false} href="/kontakt">
                    <Button mt={4} ml={{ base: 0, md: 4 }} variant="solid">
                      Kontakt
                    </Button>
                  </RouterLink>
                </FadeInAnimation>
              </Flex>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </Container>
      <ModalAd
        isToggled={isToggledAdModal}
        setToggle={setToggleAdModal}
      ></ModalAd>
      <Container variant="layoutContainer" mt={12}>
        <BannerVeoJadeklinik />
      </Container>
      <LogoHeadlineTeaserVeo
        headline="Wir sind für Sie da."
        subheadline="Mit all unserem Wissen."
      />{" "}
      <Container variant="layoutContainer" mt={12}>
        <ImgTextModule
          src={"short-master005.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h={{ base: "80vh", md: "50vh", lg: "50vh", xl: "50vh" }}
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "md", lg: "md" }}
              >
                Der Patient im Mittelpunkt.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Dr. Jann Voltmann
              </Text>
            </FadeInAnimation>

            <Flex
              mt={0}
              flexWrap="wrap"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent="start"
            >
              <Box>
                <MaskSlidInAnimation threshold={1} duration={1}>
                  <Heading
                    maxW={{ base: "100%", md: "50%" }}
                    mr={32}
                    mt={2}
                    color={"white"}
                    fontSize={{ lg: "2xl", md: "xl", base: "xl" }}
                    fontWeight="normal"
                    w={{ base: "100%", md: "50%" }}
                    letterSpacing="tight"
                  >
                    Mund-, Kiefer- und Gesichtschirurg.
                  </Heading>
                </MaskSlidInAnimation>{" "}
                {/* <FadeInAnimation
                  threshold={0.4}
                  delay={1}
                  duration={0.7}
                  initialX={0}
                  initialY={10}
                >
                  <Button
                    mt={4}
                    onClick={() => setToggleDVT(true)}
                    variant="ghost"
                    color="white"
                  >
                    Mehr erfahren
                  </Button>
                </FadeInAnimation> */}
              </Box>

              <Spacer />
            </Flex>
          </Box>
        </ImgTextModule>
        <ThreeColumnGridBoxesDocs />
      </Container>
      <QuadTeaser />
    </Layout>
  )
}

export default Blank
